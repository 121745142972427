import { v4 as uuidv4 } from "uuid";
import {
  InvitationTabType,
  lobbbyEventsTabType,
  RsvpTabType,
  WebsiteTabType,
  TabType,
  EventDetailsTabType,
  WalletTabType,
  youtubeLiveTabTypes,
  GuestsTabType,
  photoBookTabTypes,
} from "src/utils/types";
import styles from "./tab.module.scss";

interface TabProps {
  tabItems: string[];
  handleTab: (element: any) => void;
  tabState:
    | TabType
    | GuestsTabType
    | InvitationTabType
    | RsvpTabType
    | lobbbyEventsTabType
    | WebsiteTabType
    | WalletTabType
    | EventDetailsTabType
    | youtubeLiveTabTypes
    | photoBookTabTypes;
  count?: Record<string, number | null | undefined>;
}

const Tab: React.FC<TabProps> = ({ count, tabItems, handleTab, tabState }) => {
  return (
    <div className={styles["tab"]}>
      {tabItems.map((element) => {
        return (
          <button
            className={styles["tab__button"]}
            key={uuidv4()}
            onClick={() => handleTab(element as TabType)}
            data-active={!!(tabState === element)}
            role="tab"
            aria-label={`${element}-tab`}
          >
            {element.replace(/([A-Z])/g, " $1").trim()}
            {/*!!count &&
            !!count[element] &&
            count[element] !== null &&
            count[element] !== undefined ? (
              <div className={styles["tab__count"]}>{count[element]}</div>
            ) : (
              ""
							)*/}
          </button>
        );
      })}
    </div>
  );
};

export default Tab;
