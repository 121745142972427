import { useContext } from "react";
import { AppContext, ValueProps } from "src/context";
import { useQuery, useMutation } from "@apollo/client";
import {
  createLiveSchedule,
  createLiveScheduleVariables,
  eventParts,
  getLiveSchedule_getLiveSchedule_data,
  updateLiveSchedule,
  updateLiveScheduleVariables,
} from "src/types/api";
import { EventParts } from "src/graphql/queries";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik, FormikProps } from "formik";
import Input from "src/components/Input";
import Button from "src/components/Button";
import {
  CreateLiveScehdule,
  UpdateLiveSchedule,
} from "src/graphql/mutations/event.mutation";
import InnerLoading from "src/components/InnerLoading";
import { GetLiveSchedules } from "src/graphql/queries/event.queries";
import moment from "moment";

interface IEditScheduleForm {
  event_part: string;
  stream_link: string;
  date: string;
  time: string;
}

const EditScheduleSchema = Yup.object().shape({
  event_part: Yup.string().required("Event Part is Required"),
  stream_link: Yup.string()
    .required("Please enter a stream link")
    .url("Please enter a valid link"),
  date: Yup.date()
    .min(
      new Date(new Date().setDate(new Date().getDate() - 1)).toISOString(),
      "You cannot choose a past date"
    )
    .required("Please enter a date"),
  time: Yup.string().required("Please enter a time"),
});

interface IEditLiveSchedule {
  close: () => void;
  schedule: getLiveSchedule_getLiveSchedule_data;
}

const EditLiveSchedule = ({ close, schedule }: IEditLiveSchedule) => {
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const eventUuid = contextValue?.eventUuid;
  const navigate = useNavigate();

  const { data: parts, loading: eventPartLoading } = useQuery<eventParts>(
    EventParts,
    {
      variables: {
        eventId: eventId,
        page: 1,
        pageSize: 50,
      },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(<p>{error?.message ?? "An error occurred"}</p>);
        } else {
          navigate("/clear");
        }
      },
    }
  );

  const [updateLiveSchelude, { loading: updateLiveScheduleLoading }] =
    useMutation<updateLiveSchedule, updateLiveScheduleVariables>(
      UpdateLiveSchedule,
      {
        onCompleted: (res) => {
          close();
        },
        onError(error) {
          if (error?.message !== "unauthenticated") {
            toast.error(
              <p className="toast">{error?.message ?? "An error occured"}</p>
            );
          } else {
            navigate("/clear");
          }
        },
      }
    );

  return (
    <div className="w-screen h-screen md:h-max md:max-w-[32.5rem] md:min-h-[29.6rem] bg-white rounded-[0.5rem] p-[2.5rem] flex flex-col gap-[1.5rem]">
      <div className="flex flex-col mb-4">
        <h3 className="text-dark-1 text-[1.25rem] font-[600] leading-[1.5rem]">
          Update Schedule Stream
        </h3>
        <p className="text-secondary-3 text-[0.875rem] leading-[1.3125rem] tracking-[-0.0175rem]">
          Update details of stream
        </p>
      </div>
      {eventPartLoading ? (
        <div className="h-[20vh]">
          <InnerLoading />
        </div>
      ) : (
        <Formik
          initialValues={{
            date: moment(schedule.time).format("YYYY-MM-DD"),
            event_part: `${
              parts?.eventParts?.find((i) => i.id === schedule.event_part.id)
                ?.uuid
            }`,
            stream_link: schedule.link,
            time: moment(schedule.time).format("HH:mm"),
          }}
          validationSchema={EditScheduleSchema}
          onSubmit={(values, _actions) => {
            eventId &&
              updateLiveSchelude({
                variables: {
                  updateLiveScheduleId: schedule.id,
                  date: `${values?.date}`,
                  link: values?.stream_link,
                  time: `${values?.time}:00`,
                },
                refetchQueries: [
                  {
                    query: GetLiveSchedules,
                    variables: {
                      eventUuid: `${eventUuid}`,
                      page: 1,
                      pageSize: 50,
                    },
                  },
                ],
              });
          }}
        >
          {({ isValid, setFieldValue }: FormikProps<IEditScheduleForm>) => (
            <Form className="flex flex-col gap-[1.5rem]">
              <div className="flex flex-col gap-2">
                <label htmlFor={"event_part"}>{"Event Part"}</label>
                <Field
                  className="h-[2.5rem] bg-secondary-5"
                  as="select"
                  name="event_part"
                  onChange={(e: any) =>
                    setFieldValue("event_part", e.target.value)
                  }
                >
                  <option disabled hidden value="">
                    Select Event Part
                  </option>
                  {parts?.eventParts?.map((i, idx) => (
                    <option key={idx} value={i?.uuid}>
                      {i?.part}
                    </option>
                  ))}
                </Field>
                {!parts?.eventParts?.length ? (
                  <p className="text-[12px] md:text-[14px] font-sans text-secondary-3">
                    No event part created, pls create an event part{" "}
                    <span
                      onClick={() => navigate(`/event-details?tab=myParts`)}
                      className="text-green-500 underline cursor-pointer"
                    >
                      here
                    </span>
                  </p>
                ) : (
                  <></>
                )}
              </div>

              <Input
                type="text"
                name="stream_link"
                label="Stream Link"
                placeholder="Add your streaming link"
              />
              <div className="grid grid-cols-2 gap-4">
                <Input
                  type="date"
                  name="date"
                  label="Date"
                  placeholder="dd/mm/yyyy"
                />
                <Input
                  type="time"
                  name="time"
                  label="Time"
                  placeholder="00 : 00"
                />
              </div>
              <div className="flex justify-end gap-[0.5rem] mt-[1.5rem]">
                <Button
                  wrapperBtnClassName="!w-max"
                  type="submit"
                  label="Cancel"
                  design="secondary"
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  wrapperBtnClassName="!w-max"
                  type="submit"
                  label="Update Schedule"
                  design="primary"
                  loader={updateLiveScheduleLoading}
                  handleClick={() => {}}
                  disabled={!isValid}
                >
                  <span>Update Schedule</span>
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditLiveSchedule;
