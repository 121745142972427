import { useState, useContext } from "react";
import styles from "./photobook.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import { GetAlbumDetails } from "src/graphql/queries";
import InnerLoading from "src/components/InnerLoading";
// import Pagination from "src/components/Pagination";
import * as Popover from "@radix-ui/react-popover";
import { AppContext, ValueProps } from "src/context";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import {
  deleteAlbumMedia,
  deleteAlbumMediaVariables,
  getAlbumDetails,
  setHeroImage,
  setHeroImageVariables,
} from "src/types/api";
import DialogModal from "src/components/Dialog";
import { LazyLoadImage } from "react-lazy-load-image-component";
import EmptyState from "./EmptyState";
import AddPictures from "./AddPictures";
import Icon from "src/components/Icon";
import { DeleteAlbumMedia } from "src/graphql/mutations";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { SetHeroImage } from "src/graphql/mutations/event.mutation";
import DeleteSelected from "./DeleteSelected";
import DashboardLayout from "src/layouts/dashboard";
import Tab from "src/components/Tab";
import { photoBookTabTypes } from "src/utils/types";
import YourPhotos from "./yourPhotos";
import GuestsUploads from "./guestsUploads";
import { cn } from "src/lib/utils";

const tabItems: photoBookTabTypes[] = ["yourPhotos", "guestsUploads"];
const Photobook: React.FC = () => {
  const [tabState, setTabState] = useState<photoBookTabTypes>("yourPhotos");
  const handleTab = (element: photoBookTabTypes) => {
    setTabState(element);
  };
  const [addLiveEventDefaultOpen, setAddLiveEventDefaultOpen] = useState(false);

  const tab: Record<photoBookTabTypes, JSX.Element> = {
    yourPhotos: <YourPhotos />,
    guestsUploads: <GuestsUploads />,
  };

  return (
    <DashboardLayout pageName="Photobook">
      <div className={styles["photobook"]}>
        <div className={styles["photobook__tabs"]}>
          <Tab tabItems={tabItems} tabState={tabState} handleTab={handleTab} />
        </div>
        <div
          className={cn(
            styles["photobook__main"],
            `px-[1.2rem] sm:px-0  pb-[2rem]`
          )}
        >
          {tab[tabState]}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Photobook;
