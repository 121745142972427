interface IconProps {
  fill?: string;
}
const LiveHall = ({ fill }: IconProps) => {
  return (
    <svg
      width="24"
      height="13"
      viewBox="0 0 52 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.4567 7.13604C51.1333 6.9312 50.7129 6.90964 50.3679 7.08213L42.2068 11.071V4.64567C42.2068 2.3278 40.3202 0.441162 38.0023 0.441162H4.20451C1.88664 0.441162 0 2.3278 0 4.64567V23.8894C0 26.2073 1.88664 28.0939 4.20451 28.0939H38.0023C40.3202 28.0939 42.2068 26.2073 42.2068 23.8894V17.464L50.3679 21.4529C50.5296 21.5284 50.6913 21.5715 50.8638 21.5715C51.0686 21.5715 51.2735 21.5176 51.4567 21.399C51.7909 21.1942 51.985 20.8276 51.985 20.4395V8.09553C51.985 7.70742 51.7909 7.34087 51.4567 7.13604ZM21.098 18.6284C18.6939 18.6284 16.7426 16.677 16.7426 14.2729C16.7426 11.8688 18.6939 9.91748 21.098 9.91748C23.5021 9.91748 25.4535 11.8688 25.4535 14.2729C25.4535 16.677 23.5021 18.6284 21.098 18.6284Z"
        fill={fill}
      />
      <path
        d="M28.8886 13.7312L24.7729 18.607C23.9731 19.5685 22.787 20.1246 21.536 20.1246C20.2851 20.1246 19.099 19.5685 18.2988 18.607L14.3573 13.858C13.6513 13.0113 13.2919 11.9285 13.3504 10.8279C13.4092 9.72687 13.8819 8.68892 14.6741 7.92216C15.5311 7.13233 16.6787 6.73645 17.8397 6.82987C19.006 6.91493 20.0816 7.49012 20.7999 8.41274L21.6232 9.39417L22.3196 8.55504C23.0045 7.69127 23.9915 7.11931 25.0814 6.95466C26.1714 6.7905 27.2832 7.0453 28.1923 7.66857C29.0894 8.38089 29.6602 9.42597 29.7751 10.5656C29.9246 11.6977 29.6042 12.8416 28.8887 13.7312H28.8886Z"
        fill="white"
      />
    </svg>
  );
};

export { LiveHall };
