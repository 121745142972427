interface IconProps {
  fill: string;
}

const Guests = ({ fill }: IconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 31 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.6087 11.4923C25.4795 11.4738 25.3502 11.4738 25.221 11.4923C22.3591 11.4 20.0881 9.05511 20.0881 6.1748C20.0881 3.2391 22.4699 0.838837 25.4241 0.838837C28.3598 0.838837 30.76 3.22063 30.76 6.1748C30.7416 9.05511 28.4706 11.4 25.6087 11.4923Z"
        fill={fill}
      />
      <path
        d="M5.13286 11.4923C5.26211 11.4738 5.39135 11.4738 5.5206 11.4923C8.38245 11.4 10.6535 9.05511 10.6535 6.1748C10.6535 3.22063 8.27167 0.838837 5.3175 0.838837C2.3818 0.838837 0 3.22063 0 6.1748C0 9.05511 2.27102 11.4 5.13286 11.4923Z"
        fill={fill}
      />
      <path
        d="M15.6195 26.4478C15.4718 26.4293 15.3056 26.4293 15.1394 26.4478C11.7422 26.337 9.02802 23.549 9.02802 20.1148C9.04648 16.6067 11.8714 13.7633 15.3979 13.7633C18.906 13.7633 21.7494 16.6067 21.7494 20.1148C21.7309 23.549 19.0353 26.337 15.6195 26.4478Z"
        fill={fill}
      />
    </svg>
  );
};
export { Guests };
