import { useEffect, useState } from "react";
import DashboardLayout from "src/layouts/dashboard";
import GeneralInformation from "./GeneralInfo";
import MyParts from "./MyParts";
import styles from "./styles.module.scss";
import Tab from "src/components/Tab";
import { EventDetailsTabType } from "src/utils/types";
import { useSearchParams } from "react-router-dom";

// Hidding preferences in production
const tabItems = ["generalInformation", "myParts"];
// .filter(
//   (i) => i !== "preferences" || process.env.REACT_APP_INAWO_ENV === "dev"
// );

function EventDetails() {
  let [searchParams] = useSearchParams();

  const [tabState, setTabState] =
    useState<EventDetailsTabType>("generalInformation");

  const handleTab = (element: EventDetailsTabType) => {
    setTabState(element);
  };

  const tab: Record<EventDetailsTabType, JSX.Element> = {
    generalInformation: <GeneralInformation />,
    myParts: <MyParts />,
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      setTabState(searchParams.get("tab") as EventDetailsTabType);
    }
  }, [searchParams]);

  return (
    <DashboardLayout pageName="Event Details">
      <div className={styles["event-details"]}>
        <div className={styles["event-details__tab-container"]}>
          <Tab tabItems={tabItems} tabState={tabState} handleTab={handleTab} />
        </div>
        <div className={styles["event-details__main-container"]}>
          {tab[tabState]}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default EventDetails;
