import { useEffect, useState } from "react";
import styles from "./website.module.scss";
import { WebsiteTabType, SectionWebsiteCountType } from "src/utils/types";
import Tab from "src/components/Tab";
import DashboardLayout from "src/layouts/dashboard";
import Templates from "./Templates";
import Messages from "./Messages";
import BackStory from "./BackStory";
import EventGallery from "../photobook";
import { useSearchParams } from "react-router-dom";

const tabItems = ["websiteTemplates", "backstory", "messages"];

const Website = () => {
  const [count, setCount] = useState<SectionWebsiteCountType>(
    {} as SectionWebsiteCountType
  );

  let [searchParams] = useSearchParams();

  const [tabState, setTabState] = useState<WebsiteTabType>("websiteTemplates");

  const handleTab = (element: WebsiteTabType) => {
    setTabState(element);
  };

  const tab: Record<WebsiteTabType, JSX.Element> = {
    websiteTemplates: <Templates />,
    messages: <Messages count={count} setCount={setCount} />,
    backstory: <BackStory />,
    // photobook: <EventGallery />,
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      setTabState(searchParams.get("tab") as WebsiteTabType);
    }
  }, [searchParams]);

  return (
    <DashboardLayout pageName="Website">
      <div className={styles["website"]}>
        <div className={styles["website__tab-container"]}>
          <Tab
            count={count}
            tabItems={tabItems}
            tabState={tabState}
            handleTab={handleTab}
          />
        </div>
        <div className={styles["website__main-container"]}>{tab[tabState]}</div>
      </div>
    </DashboardLayout>
  );
};

export default Website;
