import React, { useContext, useState } from "react";
import SendInvitesModalFooter from "./SendInvitesModalFooter";
import InviteOptions from "./InviteOptions";
import { usePaystackPayment } from "react-paystack";
import { useMutation, useQuery } from "@apollo/client";
import {
  Channel,
  getUsername,
  getWalletsV1,
  getWalletsV1Variables,
  invitationListFilter,
  sendInvitations,
  sendInvitationsVariables,
  verifyTransaction,
  verifyTransactionVariables,
} from "src/types/api.d";
import { GetEventWalletsV1 } from "src/graphql/queries/wallets.queries";
import toast from "react-hot-toast";
import { AppContext, ValueProps } from "src/context";
import { useNavigate } from "react-router-dom";
import { FilterInvitationList, GetUsername } from "src/graphql/queries";
import { SendInvitesStepsTypes } from "..";
import CostDisplay from "./CostDisplay";
import { SendInvitations, VerifyTransaction } from "src/graphql/mutations";
import InnerLoading from "src/components/InnerLoading";

interface PaymentOptionsProps {
  setStep: React.Dispatch<React.SetStateAction<SendInvitesStepsTypes>>;
  cost: number | undefined;
  setOpenInviteModal: React.Dispatch<React.SetStateAction<boolean>>;
  channel: string;
  invitationIDs: string[];
  invitationFilter: invitationListFilter;
}

const PaymentOptions = ({
  setStep,
  cost,
  setOpenInviteModal,
  invitationIDs,
  channel,
  invitationFilter,
}: PaymentOptionsProps) => {
  const [active, setActive] = useState<string>("");
  const [userDetails, setUserDetails] = useState<getUsername>();
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const eventUuid = contextValue?.eventUuid;
  const isAdmin = contextValue?.isAdmin;
  const navigate = useNavigate();

  useQuery<getUsername>(GetUsername, {
    fetchPolicy: "network-only",
    onCompleted(response) {
      setUserDetails(response);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const fullName = `${userDetails?.user?.first_name} ${userDetails?.user?.last_name}`;

  const updateActiveElement = (id: string) => {
    setActive(active !== id ? id : "");
  };

  const [sendInvitation, { loading }] = useMutation<
    sendInvitations,
    sendInvitationsVariables
  >(SendInvitations, {
    onCompleted() {
      setStep("inviteSent");
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const doSendInvitation = () => {
    eventUuid &&
      sendInvitation({
        variables: {
          eventId: eventUuid,
          req: {
            all: false,
            pending: false,
            channel: channel as Channel,
            list_id: invitationIDs,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: FilterInvitationList,
            fetchPolicy: "network-only",
            variables: {
              eventId: eventId,
              page: 1,
              pageSize: 1000,
              filter: invitationFilter,
            },
          },
        ],
      });
  };

  const [veryfyTransaction] = useMutation<
    verifyTransaction,
    verifyTransactionVariables
  >(VerifyTransaction, {
    onCompleted: (res) => {
      if (res.verifyTransaction.reference) {
        doSendInvitation();
      }
    },
    onError(error) {
      if (error.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      }
    },
  });

  const paystackCharge = React.useMemo(() => {
    const preTotal = parseInt(`${cost}`);
    const percentCharge = 0.015 * preTotal;
    const fullCharge = percentCharge + 100;
    if (preTotal < 2500) {
      return { charge: percentCharge };
    } else if (fullCharge > 2000) {
      return { charge: 2000, with100: true };
    } else {
      return { charge: fullCharge, with100: true };
    }
  }, [cost]);

  const payStackConfigMetaData = {
    user_id: userDetails?.user?.id ?? "",
    event_id: eventId,
    trans_type: "credit",
    currency: "NGN",
    description: "paid for invites",
    creditor_alias: fullName,
    charges: paystackCharge?.charge,
    amount: cost,
    kind: channel,
  };

  let paystackconfigData = {
    reference: new Date().getTime().toString(),
    email: userDetails?.user?.email ?? "",
    amount: parseInt(`${Number(cost) * 100}`),
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY || "",
    metadata: {
      ...payStackConfigMetaData,
      custom_fields: Object.entries(payStackConfigMetaData)?.map(([k, v]) => {
        return {
          display_name: k,
          variable_name: k,
          value: v,
        };
      }),
    },
  };

  let initializePaymentWithPaystack = usePaystackPayment(paystackconfigData);

  const onSuccessWithPaystack = () => {
    veryfyTransaction({
      variables: {
        reference: paystackconfigData.reference,
      },
    });
  };

  const handleContinue = () => {
    if (active === "paystack") {
      setOpenInviteModal(false);
      initializePaymentWithPaystack(onSuccessWithPaystack);
    } else {
      doSendInvitation();
    }
  };

  const { data: eventWalletDetails, loading: eventWalletLoading } = useQuery<
    getWalletsV1,
    getWalletsV1Variables
  >(GetEventWalletsV1, {
    skip: !eventId || !isAdmin,
    variables: {
      eventId: !!eventId ? eventId : 0,
    },
    fetchPolicy: "network-only",
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const inawaoNairaBalance = eventWalletDetails?.getWalletsV1[1]?.balance ?? 0;

  return (
    <>
      <div className="pt-10 px-10 pb-1">
        <h1 className="text-2xl font-medium mb-10">
          How would you want to pay?
        </h1>
        <CostDisplay cost={cost} />
        {eventWalletLoading ? (
          <div className="w-full flex justify-center py-8">
            <InnerLoading />
          </div>
        ) : (
          <div className="flex flex-col gap-4 pt-6 mb-4">
            <InviteOptions
              type="Paystack"
              active={active === "paystack"}
              onClick={() => updateActiveElement("paystack")}
            />
            {!!inawaoNairaBalance ? (
              <InviteOptions
                active={active === "inawo Wallet"}
                onClick={() => updateActiveElement("inawo Wallet")}
                type="Inawo Wallet"
                description={`N${inawaoNairaBalance.toLocaleString()}`}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>

      <SendInvitesModalFooter
        firstButtonText={"Go Back"}
        firstButtonFunction={() => setStep("sendInvitesPrompt")}
        secondButtonText={"Continue"}
        secondButtonFunction={handleContinue}
        disableSecondButton={active === "" || eventWalletLoading}
        secondButtonLoader={active === "inawo Wallet" && loading}
      />
    </>
  );
};

export default PaymentOptions;
