import Icon from "src/components/Icon";

const GuestsUploads = () => {
  return (
    <div className="flex flex-col items-center pt-[60px]">
      <div className="absolute bg-[#7D918D80] w-[274px] h-[249px] blur-[105px]"></div>
      <div>
        <Icon iconName="uploadsComingSoon" />
      </div>
      <div className="px-5 py-2.5 rounded-[20px] bg-primary-6 w-max mt-[38px]">
        <h4 className="text-sm font-medium tracking-[0.84px] text-white">
          COMING SOON
        </h4>
      </div>
      <div className="max-w-[250px] mt-[30px]">
        <p className="text-base text-center text-[#707070]">
          We’ll be showing all your guests activity here, coming very soon{" "}
        </p>
      </div>
    </div>
  );
};
export default GuestsUploads;
