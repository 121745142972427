import DashboardLayout from "src/layouts/dashboard";
import { useEffect, useState } from "react";
import { InvitationTabType } from "src/utils/types";
import Tab from "src/components/Tab";
import styles from "./styles.module.scss";
import InviteList from "./invite-list";
import InvitationCard from "./invitation-card";
import Categories from "../guests/categories";
import { useSearchParams } from "react-router-dom";
import AddGuest from "./AddGuests";

const tabItems: InvitationTabType[] = [
  "invitationCard",
  "sendInvites",
  "inviteList",
];

function Invitations() {
  let [searchParams] = useSearchParams();

  const [tabState, setTabState] = useState<InvitationTabType>("invitationCard");
  const handleTab = (element: InvitationTabType) => {
    setTabState(element);
  };

  const tab: Record<InvitationTabType, JSX.Element> = {
    inviteList: <InviteList />,
    sendInvites: <AddGuest />,
    invitationCard: <InvitationCard />,
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      setTabState(searchParams.get("tab") as InvitationTabType);
    }
  }, [searchParams]);

  return (
    <DashboardLayout pageName="Invitations">
      <div className={styles["invitations"]}>
        <div className={styles["invitations__tabs"]}>
          <Tab tabItems={tabItems} tabState={tabState} handleTab={handleTab} />
        </div>
        <div className={styles["invitations__main"]}>{tab[tabState]}</div>
      </div>
    </DashboardLayout>
  );
}

export default Invitations;
