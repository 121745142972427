import { useState, useContext } from "react";
import styles from "../photobook.module.scss";
import { useMutation, useQuery } from "@apollo/client";
import { GetAlbumDetails } from "src/graphql/queries";
import InnerLoading from "src/components/InnerLoading";
// import Pagination from "src/components/Pagination";
import * as Popover from "@radix-ui/react-popover";
import { AppContext, ValueProps } from "src/context";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";
import {
  deleteAlbumMedia,
  deleteAlbumMediaVariables,
  getAlbumDetails,
  setHeroImage,
  setHeroImageVariables,
} from "src/types/api";
import DialogModal from "src/components/Dialog";
import { LazyLoadImage } from "react-lazy-load-image-component";
import EmptyState from "../EmptyState";
import AddPictures from "../AddPictures";
import Icon from "src/components/Icon";
import { DeleteAlbumMedia } from "src/graphql/mutations";
import { useNavigate } from "react-router-dom";
import Button from "src/components/Button";
import { SetHeroImage } from "src/graphql/mutations/event.mutation";
import DeleteSelected from "../DeleteSelected";

const YourPhotos: React.FC = () => {
  const [pageSize] = useState(200);
  const [page] = useState(1);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const eventUUID = contextValue?.eventUuid;
  const navigate = useNavigate();

  const { data: images, loading: getAlbumDetailsLoading } =
    useQuery<getAlbumDetails>(GetAlbumDetails, {
      variables: {
        eventId: eventUUID,
        page: page,
        pageSize: pageSize,
      },
      // onCompleted(response) {
      //   if (!!response?.getAlbumDetails && response?.getAlbumDetails.length) {
      //     setTotalPages(
      //       Math.floor(response?.getAlbumDetails.length / pageSize)
      //     );
      //   }
      // },
      fetchPolicy: "cache-and-network",
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    });

  const [deleteAlbumMediaFn] = useMutation<
    deleteAlbumMedia,
    deleteAlbumMediaVariables
  >(DeleteAlbumMedia, {
    onCompleted() {
      toast.success(<p className="toast">Successfully deleted!</p>);
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const [setHeroImageFn] = useMutation<setHeroImage, setHeroImageVariables>(
    SetHeroImage,
    {
      onCompleted() {
        toast.success(<p className="toast">Successfully set hero image!</p>);
      },
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deleteList, setDeleteList] = useState<string[]>([]);

  const handleChange = (id: string) => {
    if (deleteList.includes(id)) {
      setDeleteList(deleteList.filter((item) => item !== id));
    } else {
      setDeleteList([...deleteList, id]);
    }
  };

  return (
    <div>
      {getAlbumDetailsLoading ? (
        <div className={styles["photobook__loading"]}>
          <InnerLoading />
        </div>
      ) : !!images?.getAlbumDetails && images?.getAlbumDetails.length > 0 ? (
        <>
          <div className={styles["photobook__trigger__group"]}>
            <p className={styles["photobook__trigger__number"]}>
              Number of images: <span>{images.getAlbumDetails.length}</span>
            </p>
            <div className={styles["photobook__trigger__right"]}>
              {showDelete ? (
                <>
                  <div>
                    <Button
                      label="cancel"
                      type="button"
                      handleClick={() => {
                        setShowDelete(false);
                        setDeleteList([]);
                      }}
                      design="secondary"
                    >
                      Cancel
                    </Button>
                  </div>
                  <div>
                    <DialogModal
                      open={openDelete}
                      onOpenChange={setOpenDelete}
                      trigger={
                        <div
                          onClick={() => setOpenDelete(true)}
                          className={styles["photobook__delete-selected"]}
                        >
                          <span>Delete Selected</span>
                        </div>
                      }
                    >
                      <DeleteSelected
                        setShowDelete={setShowDelete}
                        setOpen={setOpenDelete}
                        page={page}
                        pageSize={pageSize}
                        deleteList={deleteList}
                      />
                    </DialogModal>
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <DialogModal
                      open={openAdd}
                      onOpenChange={setOpenAdd}
                      trigger={
                        <div
                          onClick={() => setOpenAdd(true)}
                          className={styles["photobook__trigger"]}
                        >
                          <span>+Add media</span>
                        </div>
                      }
                    >
                      <AddPictures
                        setToggleModal={setOpenAdd}
                        page={page}
                        pageSize={pageSize}
                      />
                    </DialogModal>
                  </div>
                  <div className="">
                    <Button
                      label="delete"
                      type="button"
                      handleClick={() => {
                        setShowDelete(!showDelete);
                        setDeleteList([]);
                      }}
                      disabled={false}
                      design="secondary"
                    >
                      Delete
                    </Button>
                  </div>
                  <a
                    href={`https://${contextValue?.eventSlug}.${process.env.REACT_APP_INAWO_ALBUM_SITE_END}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      label="delete"
                      type="button"
                      disabled={false}
                      design="secondary"
                      className="flex items-center gap-1"
                    >
                      <Icon iconName="eyeOpenOutline" />
                      <span> See photobook</span>
                    </Button>
                  </a>
                </>
              )}
            </div>
          </div>

          <div className={styles["photobook__container"]}>
            {images?.getAlbumDetails?.map((element, index) => {
              return (
                !!element?.url && (
                  <div
                    className={styles["photobook__card__container"]}
                    key={uuidv4()}
                  >
                    {showDelete && (
                      <div className={styles["photobook__card__checkbox"]}>
                        <input
                          type="checkbox"
                          checked={
                            deleteList.includes(element.id) ? true : false
                          }
                          onChange={() => handleChange(element.id)}
                        />
                      </div>
                    )}
                    <div className={styles["photobook__card__picture"]}>
                      <Icon iconName="picture" />
                    </div>
                    <div className={styles["photobook__card__delete"]}>
                      <Popover.Root>
                        <Popover.Trigger
                          className={
                            styles["photobook__dropdown-container-button"]
                          }
                        >
                          <Icon iconName="menuVertical" />
                          <span className="visually-hidden">open dropdown</span>
                        </Popover.Trigger>
                        <Popover.Content
                          className={styles["photobook__dropdown"]}
                        >
                          <div className={styles["photobook__dropdown-list"]}>
                            {
                              <>
                                <div
                                  onClick={() =>
                                    eventId &&
                                    setHeroImageFn({
                                      variables: {
                                        image: element?.url ?? "",
                                        setHeroImageEventId2: eventId,
                                      },
                                    })
                                  }
                                  className={
                                    styles["photobook__dropdown-list__btn"]
                                  }
                                >
                                  Set as hero image
                                </div>
                                <div
                                  onClick={() =>
                                    eventUUID &&
                                    deleteAlbumMediaFn({
                                      variables: {
                                        eventId: eventUUID!,
                                        mediaId: element.id,
                                      },
                                      refetchQueries: [
                                        {
                                          query: GetAlbumDetails,
                                          fetchPolicy: "cache-only",
                                          variables: {
                                            eventId: eventUUID,
                                            page: page,
                                            pageSize: pageSize,
                                          },
                                        },
                                      ],
                                    })
                                  }
                                  className={
                                    styles[
                                      "eventGallery__dropdown-list__remove"
                                    ]
                                  }
                                >
                                  Delete
                                </div>
                              </>
                            }
                          </div>
                        </Popover.Content>
                      </Popover.Root>
                    </div>
                    <DialogModal
                      key={uuidv4()}
                      trigger={
                        <div className={styles["photobook__card"]}>
                          <img src={element?.url} alt={`event-${index + 1}`} />
                        </div>
                      }
                    >
                      <div className={styles["website__carousel"]}>
                        <div className={styles["website__carousel-image"]}>
                          <LazyLoadImage
                            src={element?.url}
                            placeholderSrc={element?.url}
                            alt={`img from cloudinary: ${element?.url}`}
                            effect="blur"
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </div>
                    </DialogModal>
                  </div>
                )
              );
            })}
          </div>
        </>
      ) : (
        <div className={styles["photobook__add-pictures-container"]}>
          <div className={styles["photobook__add-pictures"]}>
            <EmptyState page={page} pageSize={pageSize} />
          </div>
        </div>
      )}
      {/* <Pagination
        pageSize={pageSize}
        startPage={startPage}
        setPageSize={setPageSize}
        setStartPage={setStartPage}
        totalPages={totalPages}
        page={page}
        setPage={setPage}
    /> */}
    </div>
  );
};

export default YourPhotos;
