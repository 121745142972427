import styles from "./addPictures.module.scss";
import { Formik, Form, FormikProps } from "formik";
import Button from "../../../components/Button";
import * as Yup from "yup";
import { GetAlbumDetails } from "src/graphql/queries";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AppContext, ValueProps } from "src/context";
import toast from "react-hot-toast";
import { AddMedia } from "src/graphql/mutations";
import {
  addMedia,
  addMediaVariables,
  AlbumMediaTag,
  setHeroImage,
  setHeroImageVariables,
} from "src/types/api.d";
import { useMutation } from "@apollo/client";
import InnerLoading from "src/components/InnerLoading";
import CustomSelect from "src/components/InputSelect";
import { MultiValue } from "react-select";
import { useNavigate } from "react-router-dom";
import { SetHeroImage } from "src/graphql/mutations/event.mutation";

export interface Values {
  image: string;
}

const AddItemSchema = Yup.object().shape({
  image: Yup.mixed(),
});

interface AddPicturesProps {
  page: number;
  pageSize: number;
  setToggleModal: (toggleModal: boolean) => void;
}

interface TagType {
  value: string;
  label: string;
}

const AddPictures: React.FC<AddPicturesProps> = ({
  page,
  pageSize,
  setToggleModal,
}) => {
  const [heroImage, setHeroImage] = useState(false);
  const [cloudinaryLoading, setCloudinaryLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState<TagType[]>([] as TagType[]);
  const [url, setUrl] = useState("");
  const [image, setImage] = useState("");
  const contextValue = useContext<ValueProps | null>(AppContext);
  const eventId = contextValue?.eventId;
  const eventUUID = contextValue?.eventUuid;
  const formData = new FormData();
  const navigate = useNavigate();
  const [isSelected, setIsSelected] = useState(false);
  const reader = new FileReader();

  const options = Object.keys(AlbumMediaTag).map((label) => {
    return {
      label,
      value: AlbumMediaTag[label as keyof typeof AlbumMediaTag],
    };
  });

  const uploadPicture = () => {
    formData.append("file", image);
    formData.append("upload_preset", process.env.REACT_APP_CLOUDINARY_FOLDER!);
    axios
      .post(
        "https://api.Cloudinary.com/v1_1/inawo-medias/image/upload",
        formData
      )
      .then((response) => {
        setUrl(
          `${response?.data?.secure_url}?inawoHeight=${response?.data?.height}&inawoWidth=${response?.data?.width}`
        );
        setCloudinaryLoading(false);
        setImage(response.data.secure_url);
      })
      .catch((error) => {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
        setCloudinaryLoading(false);
      });
    setCloudinaryLoading(true);
  };

  const [addMedia, { loading: addMediaLoading }] = useMutation<
    addMedia,
    addMediaVariables
  >(AddMedia, {
    onCompleted() {
      setToggleModal(false);
      toast.success(
        <p className="toast">You have successfully added a picture.</p>
      );
    },
    onError(error) {
      if (error?.message !== "unauthenticated") {
        toast.error(
          <p className="toast">{error?.message ?? "An error occured"}</p>
        );
      } else {
        navigate("/clear");
      }
    },
  });

  const [setHeroImageFn] = useMutation<setHeroImage, setHeroImageVariables>(
    SetHeroImage,
    {
      onCompleted() {},
      onError(error) {
        if (error?.message !== "unauthenticated") {
          toast.error(
            <p className="toast">{error?.message ?? "An error occured"}</p>
          );
        } else {
          navigate("/clear");
        }
      },
    }
  );

  useEffect(() => {
    if (url === "" && image !== "") {
      uploadPicture();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);

  const selectChange = (option: MultiValue<TagType>) => {
    setSelectedTags([...option]);
  };

  return (
    <div className={styles["addPictures"]}>
      <div className={styles["addPictures-heading"]}>
        <h4>Add Photo</h4>
      </div>
      <Formik
        initialValues={{
          image: "",
        }}
        validationSchema={AddItemSchema}
        onSubmit={(_values, _actions) => {
          eventUUID &&
            addMedia({
              variables: {
                eventId: eventUUID!,
                url: url,
                tags: selectedTags.map((tag) => tag.value) as AlbumMediaTag[],
              },
              refetchQueries: [
                {
                  query: GetAlbumDetails,
                  variables: { eventId: eventUUID, page, pageSize },
                },
              ],
            });
          if (heroImage && eventId) {
            setHeroImageFn({
              variables: {
                image: url ?? "",
                setHeroImageEventId2: eventId,
              },
            });
          }
        }}
      >
        {(props: FormikProps<Values>) => (
          <Form className={styles["addPictures-form"]}>
            <div className={styles["addPictures-form-container"]}>
              <label htmlFor="image">Picture</label>
              <div className={styles["addPictures-image-box"]}>
                {isSelected === false && url === "" ? (
                  <>
                    <input
                      id="add-file"
                      // multiple
                      type="file"
                      className={styles["file"]}
                      name="image"
                      accept="image/*"
                      onChange={(event: any) => {
                        // console.log(event, event.target.files);
                        if (event.target.files[0] && event.target.files) {
                          reader.onload = function (e: any) {
                            setImage(e.target.result);
                          };
                          reader.readAsDataURL(event.target.files[0]);
                          setIsSelected(true);
                        }
                      }}
                      onBlur={props.handleBlur}
                    ></input>
                    <label htmlFor="add-file">
                      <span>Click here to upload file</span>
                      <br />
                      <span>.png or .jpg only</span>
                    </label>
                  </>
                ) : isSelected === true && url === "" ? (
                  <InnerLoading />
                ) : (
                  <img src={image} alt="addPictures" />
                )}
              </div>
              <CustomSelect
                placeholder="e.g Bride"
                isMulti
                options={options}
                closeMenuOnSelect={false}
                value={selectedTags}
                label={"Tag"}
                onChange={selectChange}
              />
              <div className={styles["addPictures-select"]}>
                <p>Set as Hero Image</p>
                <label className={styles["addPictures-switch"]}>
                  <input
                    checked={heroImage}
                    type="checkbox"
                    className={styles["addPictures-input"]}
                  />
                  <span
                    onClick={() => setHeroImage((prev) => !prev)}
                    className={styles["addPictures-slider"]}
                  ></span>
                </label>
              </div>
            </div>
            <div className={styles["addPictures-button-container"]}>
              <div className={styles["addPictures-button"]}>
                <Button
                  type="submit"
                  label="Add Pictures"
                  disabled={url === "" || cloudinaryLoading}
                  loader={addMediaLoading}
                >
                  Add Photo
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddPictures;
