import DashboardLayout from "src/layouts/dashboard";
import { useEffect, useState } from "react";
import { GuestsTabType } from "src/utils/types";
import Tab from "src/components/Tab";
import styles from "./styles.module.scss";
import Categories from "./categories";
import { useSearchParams } from "react-router-dom";
import Activity from "./activity";

const tabItems: GuestsTabType[] = ["categories", "activity"];

function Guests() {
  let [searchParams] = useSearchParams();

  const [tabState, setTabState] = useState<GuestsTabType>("categories");
  const handleTab = (element: GuestsTabType) => {
    setTabState(element);
  };

  const tab: Record<GuestsTabType, JSX.Element> = {
    categories: <Categories />,
    activity: <Activity />,
  };

  useEffect(() => {
    if (searchParams.get("tab")) {
      setTabState(searchParams.get("tab") as GuestsTabType);
    }
  }, [searchParams]);

  return (
    <DashboardLayout pageName="Guests">
      <div className={styles["guests"]}>
        <div className={styles["guests__tabs"]}>
          <Tab tabItems={tabItems} tabState={tabState} handleTab={handleTab} />
        </div>
        <div className={styles["guests__main"]}>{tab[tabState]}</div>
      </div>
    </DashboardLayout>
  );
}

export default Guests;
