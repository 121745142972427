import React from "react";
import Icon from "src/components/Icon";
import { cn } from "src/lib/utils";

const Setup = ({ addLiveEvent }: { addLiveEvent: () => void }) => {
  const handleGoToSetupYoutubeChannel = () => {
    const youtubeChannelUrl = process.env.REACT_APP_YOUTUBE_CHANNEL_SETUP_LINK;
    window.open(youtubeChannelUrl, "_blank");
  };

  return (
    <div>
      <div className="flex gap-2 w-full sm:w-[32.6rem] mx-auto">
        <div className="border border-secondary-6 rounded-full w-6 h-6 sm:w-8 sm:h-8 grid place-items-center">
          <p className="text-base font-medium tracking-[0.12px]">1</p>
        </div>
        <div className="flex w-full sm:w-[27.8rem] flex-col gap-[1.5rem] sm:pt-[5px] flex-1">
          <div className="flex gap-3 items-center">
            <div>
              <h4 className="text-[17px] leading-[22px] tracking-[0.32px] text-black">
                Capture{" "}
                <span className="text-black-5">and stream using youtube</span>
              </h4>
            </div>
          </div>
          <SetupItemWrapper
            onClick={() => handleGoToSetupYoutubeChannel()}
            className="flex flex-col sm:flex-row items-center gap-2 justify-between hover:bg-primary-5/10 transition-colors ease-in-out duration-300"
          >
            <div className="flex items-center gap-[13px]">
              <Icon iconName="greenYoutube" />
              <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
                Open a YouTube channel
              </span>
            </div>
            <button className="px-[18px] w-full sm:w-fit py-[5px] flex justify-center items-center gap-2.5 text-[13px] leading-[20px] -tracking-[0.261px] text-[#27272A] border border-solid border-[#E8E8EB] rounded-[6px]">
              Open
              <Icon iconName="sm-arrow-angled" />
            </button>
          </SetupItemWrapper>
          <SetupItemWrapper className="flex flex-col gap-[0.5rem]">
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              Tap on the video Icon and tap the “Go Live” button
            </span>
            <span className="text-secondary-3 text-[0.875rem] leading-[1.3125rem] tracking-[-0.0175rem]">
              This is located on the top right corner of your screen
            </span>
            <div className="w-full flex mt-4 justify-center">
              <Icon iconName="youtubeLiveDemo" />
            </div>
          </SetupItemWrapper>
          <SetupItemWrapper className="flex flex-col gap-[0.5rem]">
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              Request for Access
            </span>
            <span className="text-secondary-3 text-[0.875rem] leading-[1.3125rem] tracking-[-0.0175rem]">
              You would be notified to “Request Access”
            </span>
          </SetupItemWrapper>
          <SetupItemWrapper>
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              Verify your Phone Number
            </span>
          </SetupItemWrapper>
          <SetupItemWrapper>
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              After 24 hours, you get access to your livestream 🎉
            </span>
          </SetupItemWrapper>
          <SetupItemWrapper>
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              Schedule streams on youtube that match your live days
            </span>
          </SetupItemWrapper>
          <SetupItemWrapper>
            <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
              Note that you’ll stream with youtube on the d day
            </span>
          </SetupItemWrapper>
        </div>
      </div>
      <div className="flex gap-2 w-full sm:w-[32.6rem] mx-auto mt-[55px]">
        <div className="border border-secondary-6 rounded-full w-6 h-6 sm:w-8 sm:h-8 grid place-items-center">
          <p className="text-base font-medium tracking-[0.12px]">2</p>
        </div>
        <div className="flex w-full sm:w-[27.8rem] flex-col gap-[1.5rem] sm:pt-[5px] flex-1">
          <div className="flex gap-3 items-center">
            <div>
              <h4 className="text-[17px] leading-[22px] tracking-[0.32px] text-black">
                Connect youtube
                <span className="text-black-5"> to your Inawo live hall</span>
              </h4>
            </div>
          </div>
          <SetupItemWrapper
            className="flex flex-col sm:flex-row gap-2 items-center justify-between hover:bg-primary-5/10 transition-colors ease-in-out duration-300"
            onClick={() => addLiveEvent()}
          >
            <div className="flex flex-col gap-2.5">
              <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
                Add days on Inawo that match youtube
              </span>
              <span className="font-[400] -leaing-[0.281px] text-[14px] text-secondary-3">
                And connect to the scheduled youtube stream
              </span>
            </div>
            <button className="px-[18px] py-[5px] w-full sm:w-fit text-[13px] leading-[20px] -tracking-[0.261px] text-[#27272A] border border-solid border-[#E8E8EB] rounded-[6px]">
              Add a day
            </button>
          </SetupItemWrapper>
          <SetupItemWrapper
            className="flex flex-col sm:flex-row gap-2 items-center justify-between hover:bg-primary-5/10 transition-colors ease-in-out duration-300"
            onClick={() => addLiveEvent()}
          >
            <div className="flex items-center gap-[13px]">
              <span className="font-[500] leading-[1.5rem] tracking-[-0.02rem] text-dark-1">
                Send your live hall link to your guests
              </span>
            </div>
            <button className="px-[18px] w-full sm:w-fit py-[5px] text-[13px] leading-[20px] -tracking-[0.261px] text-[#27272A] border border-solid border-[#E8E8EB] rounded-[6px]">
              Send link
            </button>
          </SetupItemWrapper>
        </div>
      </div>
    </div>
  );
};

export default Setup;

const SetupItemWrapper = ({
  className,
  onClick,
  children,
}: {
  className?: string;
  onClick?: () => void;
  children: React.ReactNode;
}) => {
  return (
    <div
      onClick={() => onClick?.()}
      className={cn(
        "py-[0.75rem] px-4 rounded-[0.5rem] border border-secondary-6 bg-secondary-5 cursor-pointer ",
        className
      )}
    >
      {children}
    </div>
  );
};
